import React, { useState } from "react";
import axios from "axios";
import Layout from "./Layout";
import { Link, Navigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, useIntl } from "react-intl";
import * as Validator from "validatorjs";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

axios.defaults.withCredentials = true;

const SignupForm = () => {
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const intl = useIntl();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") setEmail(value);
    else if (name === "password") setPassword(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { formatMessage } = intl;
    let validationData = {
      email,
      password,
    };
    let validationRules = {
      email: "required|email",
      password: "required",
    };
    let validation = new Validator(validationData, validationRules);

    const captchaValidated = recaptchaValue === recaptchaRef.current.getValue();

    if (validation.passes() && captchaValidated) {
      axios
        .post(process.env.REACT_APP_API_URL + "/api/auth/signup", {
          email,
          password,
          username: email,
        })
        .then((response) => {
          if (response.status === 200) {
            setUser(response.data);
            setRedirectTo("/login");
          } else {
            setUser(null);
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertMessage(error.response.data.message);
        });
    } else {
      let alertMessage = "";
      alertMessage += validation.errors.first("email")
        ? validation.errors.first("email") + " "
        : "";
      alertMessage += validation.errors.first("password")
        ? validation.errors.first("password") + " "
        : "";
      alertMessage += !captchaValidated
        ? formatMessage({ id: "app.signUpValidationRecaptcha" })
        : "";
      setShowAlert(true);
      setAlertMessage(alertMessage);
    }
  };

  const reCaptchaOnChange = (value) => {
    setRecaptchaValue(value);
  };

  const hideAlert = () => {
    setShowAlert(false);
    setAlertMessage(null);
  };

  if (redirectTo) return <Navigate to={redirectTo} />;

  return (
    <Layout>
      <Collapse in={showAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={hideAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <p>{alertMessage}</p>
        </Alert>
      </Collapse>
      <form>
        <Grid
          container
          direction={"column"}
          spacing={2}
          className={"loginContainer"}
        >
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "word.email" })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="password"
                name="password"
                type={"password"}
                onChange={handleChange}
                placeholder={"Password"}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={reCaptchaOnChange}
              ref={recaptchaRef}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={handleSubmit}
              type="submit"
              variant={"contained"}
              color={"primary"}
            >
              <FormattedMessage
                id="app.signup.label"
                defaultMessage="Sign Up"
              />
            </Button>
            <Link to={"/login"}>
              <FormattedMessage id="app.login.label" defaultMessage="Login" />
            </Link>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default SignupForm;
