import React, { useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Layout from "./Layout";
import Grid from "@mui/material/Grid";
import { AppContext } from "../utilities/context";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const handleClick = () => {
    console.log(context);
    axios
      .get(
        "/api/auth/signout/" + context.user.id
      )
      .then((response) => {
        console.log("Chau");
        context.updateUserState(null);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
    };

  return (
    <Layout>
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={1}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={10} sx={{ textAlign: "center" }}>
            Holanda Cuenta
          </Grid>
          <Grid item xs={12} sm={1}>
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            {/* <Link to={process.env.REACT_GAME_URL}>Game</Link> */}
            <Link to={"/game"} target="_blank">Game</Link>
          </Grid>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            <Link onClick={handleClick}>Chau</Link>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Account;
