import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import Container from "@mui/material/Container";
import Layout from "./Layout";
import Grid from "@mui/material/Grid";
import { VictoryChart, VictoryScatter, VictoryAxis } from "victory";

const Chart = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sensorData, setSensorData] = useState([]);

  // Ejemplo de datos: [día de la semana (0-6), hora del día (0-23)]
  // const sensorData = [
  //   { x: 0, y: 8 }, // Domingo a las 8:00
  //   { x: 0, y: 15 }, // Domingo a las 15:00
  //   { x: 1, y: 9 }, // Lunes a las 9:00
  //   { x: 2, y: 14 }, // Martes a las 14:00
  //   { x: 3, y: 11 }, // Miércoles a las 11:00
  //   { x: 4, y: 18 }, // Jueves a las 18:00
  //   { x: 5, y: 10 }, // Viernes a las 10:00
  //   { x: 6, y: 20 }, // Sábado a las 20:00
  // ];

  useEffect(() => {
    getData(page, pageSize);
  }, [page, pageSize]);

  function getData(page, pageSize) {
    axios
      .get(process.env.REACT_APP_API_URL + "/api/event/")
      .then((response) => {
        if (response.data) {
          // TODO set data
          setSensorData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Layout>
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {" "}
          </Grid>
          <Grid item xs={6} sm={6}>
            <VictoryChart domain={{ x: [0, 6], y: [0, 23] }}>
              <VictoryAxis
                tickValues={[0, 1, 2, 3, 4, 5, 6]}
                tickFormat={["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"]}
              />
              <VictoryAxis
                dependentAxis
                tickValues={[0, 6, 12, 18, 23]}
                tickFormat={["00:00", "06:00", "12:00", "18:00", "23:00"]}
              />
              <VictoryScatter
                style={{ data: { fill: "#c43a31" } }}
                size={5}
                data={sensorData}
              />
            </VictoryChart>
          </Grid>
          <Grid item xs={3}>
            {" "}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Chart;
