import React, { useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {AppContext} from "../utilities/context";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Navigate } from "react-router-dom";

axios.defaults.withCredentials = true;

function redirectToExternalURL() {
  window.location.href = process.env.REACT_APP_API_URL + "/api/federated/login/google";
}

const SigninForm = () => {
  const context = useContext(AppContext);
  // const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const intl = useIntl();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") setUsername(value);
    else if (name === "password") setPassword(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/api/auth/signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.status === 200) {
          context.updateUserState(response.data);
          return <Navigate to={"/account"} />;
        }
      })
      .catch((error) => {
        console.log("login error: ");
        console.log(error);
      });
  };

  return (
    <form>
      <Grid container direction={"column"} spacing={2} className={"loginContainer"}>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="username"
              name="username"
              value={username}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: "word.email" })}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              id="password"
              name="password"
              type={"password"}
              onChange={handleChange}
              placeholder={"Password"}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit}
            type={"submit"}
            variant={"contained"}
            color={"primary"}
          >
            <FormattedMessage id="app.login.label" defaultMessage="Login" />
          </Button>
          <Link to={"/signup"}>
            <FormattedMessage id="app.signup.label" defaultMessage="Signup" />
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};

export default SigninForm;