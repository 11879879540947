import React from "react";
// import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Layout from "./Layout";
import Grid from "@mui/material/Grid";
import Styles from "../../public/assets/css/main.css";
import viejo from "../viejo.jpg";

const Contact = () => {
  return (
    <Layout>
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            <div className={Styles.polaroid}>
              <img src={viejo} alt="logo" />
              <div className={Styles.caption}><strong>Walter Pormenores</strong></div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div style={{ textAlign: "center" }}>
              <ul style={{ display: "inline-block", textAlign: "left" }}>
                <li>Edad: 83 a&ntilde;os</li>
                <li>Grupo Sanguineo: A+</li>
                <li>Seguro m&eacute;dico: OSDE 310</li>
                <li>Alergias: Corticoides</li>
                <li>Medicaci&oacute;n: Anticoagulado</li>
                <li>
                  Contacto de Emergencia principal
                  <ul>
                    <li>
                      Nombre:{" "}
                      <strong>
                        <span class="hl_text_yellow">Cecilia Rayuela</span>
                      </strong>
                    </li>
                    <li>
                      Tel&eacute;fono:{" "}
                      <a className={Styles.clickMe} href="tel:+541112345678">
                        <strong>+54 11 1234-5678</strong>
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Contacto de Emergencia adicional
                  <br />
                  <ul>
                    <li>
                      Nombre:{" "}
                      <span class="hl_text_yellow">
                        <strong>Martin Pomelo</strong>
                      </span>
                    </li>
                    <li>
                      Tel&eacute;fono:{" "}
                      <a className={Styles.clickMe} href="tel:+541187654321">
                        <strong>+54 11 8765-4321</strong>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Contact;
