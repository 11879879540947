import React, { useEffect, useState, useContext } from "react";
import SigninForm from "./SigninForm";
import {AppContext} from "../utilities/context";
import LayoutMUI from "./Layout";
import { Navigate } from "react-router-dom";

function LoggedStatus(props) {
  const context = useContext(AppContext);
  if (context.user && context.user.id) return <Navigate to={"/account"} />;
  return <SigninForm />;
}

const Login = () => {
  const context = useContext(AppContext);
  useEffect(() => {
    console.log(context);
  }, []); // The empty dependency array means this effect will only run once (equivalent to componentDidMount)

    return (
      <LayoutMUI>
        <LoggedStatus updateAppState={null} />
      </LayoutMUI>
    );
}

export default Login;