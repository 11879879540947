import React from "react";
// import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Layout from "./Layout";
import Grid from "@mui/material/Grid";
import Styles from "../../public/assets/css/main.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import pulsera from "../pulsera.jpg";

const Home = () => {
  function verificarID() {
    window.location.href = "/987654";
  }
  return (
    <Layout>
      <Container maxWidth={false} disableGutters>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center", marginTop: "50px" }}>
          <TextField
                id="QuestionTypeLabel"
                inputProps={{ name: "text" }}
                label="Ingrese el codigo"
                variant="outlined"
                size="small"
              />
                    <Button
                       variant="contained"
                        size="large"
                        onClick={verificarID}
                    >
                      Verificar
                    </Button>

          </Grid>
          <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
            <img src={pulsera} className={Styles.userImage} alt="logo" />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Home;
