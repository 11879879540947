import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { AppContext } from "../utilities/context";
import Home from "./Home";
import Contact from "./Contact";
import Account from "./Account";
import Chart from "./Chart";
import NoMatch from "./NoMatch";
import { IntlProvider } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import axios from "axios";
import Spanish from "../lang/es.json";
import English from "../lang/en.json";
import Login from "./Login";
import SignupForm from "./SignupForm";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

axios.defaults.withCredentials = true;

const locales = ["es", "es-ar", "es-es"];
let locale;
let lang;

const App = () => {
  locale = navigator.language.toLowerCase();
  if (locales.includes(locale)) {
    lang = Spanish;
  } else {
    lang = English;
  }

  const [user, setUser] = useState(null);
  const updateUserState = (payload) => {
    setUser(payload);
  };

  // useEffect hook with an empty dependency array means this effect will only run once, like componentDidMount
  useEffect(() => {
    // getUser();
  }, []);

  function getUser() {
    if (!user) {
      axios
        .get("/api/test/user/")
        .then((response) => {
          if (response.data.id) {
            updateUserState(response.data);
          }
        })
        .catch((error) => {
          updateUserState(null);
        });
    }
    return user;
  }

  return (
    <AppContext.Provider value={{ user, updateUserState }}>
      <IntlProvider locale={locale} messages={lang}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/987654" element={<Contact />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<SignupForm />} />
            <Route exact path="/account" element={<Account />} />
            <Route exact path="/chart" element={<Chart />} />
            <Route element={<NoMatch />} />
          </Routes>
        </LocalizationProvider>
      </IntlProvider>
    </AppContext.Provider>
  );
};

export default App;
